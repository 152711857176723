import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul className="work-links">
    <li>
        <a href="https://learnuxd.com">
            <i className="fas fa-globe-americas fa-lg"></i>
            View site &raquo;
        </a>
    </li>
    <li>
        <a href="https://styles.learnuxd.com">
            <i className="fas fa-pencil-paintbrush fa-lg"></i>
            Design System &raquo;
        </a>
    </li>
    </ul>
    <p>{`In order to support my university teaching I built a suite of microsites and branded them under "Learn UXD." The main site provides links to each course, where students can explore lessons, activity instructions, and additional resources.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/learnuxd-lessons.png",
        "alt": null
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/images/work/learnuxd-callout.png",
        "alt": null
      }}></img></p>
    <p>{`To help unify these microsites I built a React component library and distinct design system using `}<a parentName="p" {...{
        "href": "https://fbrctr.github.io/"
      }}>{`Fabricator`}</a>{`. The entire system is set up with Netlify for continuous integration and deploy previews.`}</p>
    <ul className="work-links">
    <li>
        <a href="https://github.com/philschanely/course-components">
            <i className="fab fa-github fa-lg"></i>
            Course Compontents Repo &raquo;
        </a>
    </li>
    <li>
        <a href="https://github.com/philschanely/course-styles">
            <i className="fab fa-github fa-lg"></i>
            Design System Repo &raquo;
        </a>
    </li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/work/learnuxd-design-system.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      